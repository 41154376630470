export default {
  loading: false,

  reviews: [],
  reviewsAll: [],
  review: {},
  reviewsTotalCount: 0,
  reviewsAllTotalCount: 0,

  subordinates: [],
  subordinate: {},
  subordinatesTotalCount: 0,

  reviewTarget:{},
  reviewTargetById: {},
  reviewLastMonthTarget: {},

  reviewDepts: {},
  reviewDept: {},
  reviewDeptGoal: {},
  reviewDeptLastMonthGoal: {},

  userDashboard:{},
  reconfirmDashboard: {},

  reviewTrials: {},
  reviewTrial: {},

  reviewTrialsFinal:{},
  reviewTrialFinal:{},

  everyoneCommentById: {},

  toAllUserData: {}
};
